<style>
.scrolly {
	height: 70vh;
	overflow-y: scroll;
}

.movable {
	cursor: move;
}

.ghost {
	background-color: grey;
}

.draggable {
	margin-left: 10px;
}

.empty:after {
	padding: 10px;
	content: 'drop here';
	/* background-color: rgba(249, 249, 249, 1); */
	/* border: 1px dashed rgb(206, 206, 206); */
	-webkit-border-radius: 5px;
	border-radius: 5px;
}
</style>

<template>
	<errorContainer :error="erreur">
		<div>
			<div class="row">
				<div class="col-6 scrolly" v-if="GetDepartmentsAndFunctions">
					<b-card no-body v-for="department in GetDepartmentsAndFunctions" :key="department.message">
						<div slot="header">
							{{ department.message }}
							<div class="card-header-actions">
								<b-link class="card-header-action btn-minimize" v-b-toggle="department.id">
									<i class="icon-arrow-down"></i>
								</b-link>
							</div>
						</div>
						<b-collapse :id="department.id">
							<b-card-body>
								<div v-for="(fonction, index) in department.functions" :key="fonction.message">
									<p>{{ fonction.message }}</p>
									<draggable
										class="list-group draggable"
										:list="fonction.users"
										group="people"
										@change="log"
										ghost-class="ghost"
										v-bind:class="{ empty: fonction.users.length == 0 }"
									>
										<div class="list-group-item" v-for="user in fonction.users" :key="user.name">
											<userDisplay :user="user"></userDisplay>
										</div>
									</draggable>
									<br v-if="index != department.functions.length - 1" />
								</div>
								<br />
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
				<div class="col-6 scrolly" v-if="GetUsersWithoutFunction">
					<b-card header="Users">
						<draggable
							class="list-group"
							:list="GetUsersWithoutFunction"
							group="people"
							@change="log"
							ghost-class="ghost"
							v-bind:class="{ empty: GetUsersWithoutFunction.length == 0 }"
						>
							<div class="list-group-item" v-for="user in GetUsersWithoutFunction" :key="user.name">
								<userDisplay :user="user"></userDisplay>
							</div>
						</draggable>
					</b-card>
				</div>
			</div>
			<!-- <div class="row">
    <div class="col-12">
      {{ GetDepartmentsAndFunctions }}
    </div>
  </div> -->
		</div>
	</errorContainer>
</template>

<script>
import draggable from 'vuedraggable';
import gql from 'graphql-tag';

const userDisplay = {
	props: {
		user: Object
	},
	methods: {
		userTitle: function (user) {
			if (user.name.length > 0 || user.firstName.length > 0) {
				return user.name + ' ' + user.firstName;
			} else {
				return user.email;
			}
		}
	},
	template:
		'<div><span class="movable">{{ userTitle(user) }} </span> <a :href="\'/#/users/\' + user.id " target="_blank"><i class="icon-info"></i></a>   </div>'
};

const query_departments_functions = gql`
	query {
		GetDepartmentsAndFunctions {
			id
			message
			value
			functions {
				id
				value
				message
				users {
					id
					name
					firstName
					email
					userProjectID
				}
			}
		}
	}
`;

const query_users = gql`
	query {
		GetUsersWithoutFunction {
			id
			name
			firstName
			email
			userProjectID
		}
	}
`;

const mutation_department = gql`
	mutation ($UserProjectID: ID!, $DepartmentValue: Int!, $FunctionValue: Int!) {
		UpdateUserFunction(UserProjectID: $UserProjectID, DepartmentValue: $DepartmentValue, FunctionValue: $FunctionValue)
	}
`;

export default {
	name: 'two-lists',
	display: 'Two Lists',
	order: 1,
	components: {
		draggable,
		userDisplay
	},
	data() {
		return {
			erreur: {}
		};
	},
	apollo: {
		GetDepartmentsAndFunctions: {
			query: query_departments_functions,
			error(err) {
				this.erreur = err;
			},
			fetchPolicy: 'no-cache'
		},
		GetUsersWithoutFunction: {
			query: query_users,
			error(err) {
				this.erreur = err;
			},
			fetchPolicy: 'no-cache'
		}
	},
	methods: {
		updateDepartment: function (UserProjectID, DepartmentValue, FunctionValue) {
			this.$apollo
				.mutate({
					mutation: mutation_department,
					variables: {
						UserProjectID,
						DepartmentValue,
						FunctionValue
					}
				})
				.then((result) => {
					console.log('saved:', result);
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		findDepartmentAndFunction: function (UserProjectID) {
			for (var i = 0; i < this.GetDepartmentsAndFunctions.length; i++) {
				for (var j = 0; j < this.GetDepartmentsAndFunctions[i].functions.length; j++) {
					for (var k = 0; k < this.GetDepartmentsAndFunctions[i].functions[j].users.length; k++) {
						if (this.GetDepartmentsAndFunctions[i].functions[j].users[k].userProjectID == UserProjectID) {
							var department = this.GetDepartmentsAndFunctions[i].value;
							var func = this.GetDepartmentsAndFunctions[i].functions[j].value;
							return [department, func];
						}
					}
				}
			}
			// Dans aucun départment => a été remis à droite
			return [0, 0];
		},
		log: function (evt) {
			window.console.log(evt);

			if (evt.added) {
				window.console.log('hooora');
				window.console.log(evt.added.element.firstName);
				var values = this.findDepartmentAndFunction(evt.added.element.userProjectID);
				this.updateDepartment(evt.added.element.userProjectID, values[0], values[1]);
			}
		}
	}
};
</script>
