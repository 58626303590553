var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("errorContainer", { attrs: { error: _vm.erreur } }, [
    _c("div", [
      _c("div", { staticClass: "row" }, [
        _vm.GetDepartmentsAndFunctions
          ? _c(
              "div",
              { staticClass: "col-6 scrolly" },
              _vm._l(_vm.GetDepartmentsAndFunctions, function (department) {
                return _c(
                  "b-card",
                  { key: department.message, attrs: { "no-body": "" } },
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t" +
                          _vm._s(department.message) +
                          "\n\t\t\t\t\t\t\t"
                      ),
                      _c(
                        "div",
                        { staticClass: "card-header-actions" },
                        [
                          _c(
                            "b-link",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle",
                                  value: department.id,
                                  expression: "department.id",
                                },
                              ],
                              staticClass: "card-header-action btn-minimize",
                            },
                            [_c("i", { staticClass: "icon-arrow-down" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "b-collapse",
                      { attrs: { id: department.id } },
                      [
                        _c(
                          "b-card-body",
                          [
                            _vm._l(
                              department.functions,
                              function (fonction, index) {
                                return _c(
                                  "div",
                                  { key: fonction.message },
                                  [
                                    _c("p", [_vm._v(_vm._s(fonction.message))]),
                                    _c(
                                      "draggable",
                                      {
                                        staticClass: "list-group draggable",
                                        class: {
                                          empty: fonction.users.length == 0,
                                        },
                                        attrs: {
                                          list: fonction.users,
                                          group: "people",
                                          "ghost-class": "ghost",
                                        },
                                        on: { change: _vm.log },
                                      },
                                      _vm._l(fonction.users, function (user) {
                                        return _c(
                                          "div",
                                          {
                                            key: user.name,
                                            staticClass: "list-group-item",
                                          },
                                          [
                                            _c("userDisplay", {
                                              attrs: { user: user },
                                            }),
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                    index != department.functions.length - 1
                                      ? _c("br")
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                            _c("br"),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            )
          : _vm._e(),
        _vm.GetUsersWithoutFunction
          ? _c(
              "div",
              { staticClass: "col-6 scrolly" },
              [
                _c(
                  "b-card",
                  { attrs: { header: "Users" } },
                  [
                    _c(
                      "draggable",
                      {
                        staticClass: "list-group",
                        class: {
                          empty: _vm.GetUsersWithoutFunction.length == 0,
                        },
                        attrs: {
                          list: _vm.GetUsersWithoutFunction,
                          group: "people",
                          "ghost-class": "ghost",
                        },
                        on: { change: _vm.log },
                      },
                      _vm._l(_vm.GetUsersWithoutFunction, function (user) {
                        return _c(
                          "div",
                          { key: user.name, staticClass: "list-group-item" },
                          [_c("userDisplay", { attrs: { user: user } })],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }